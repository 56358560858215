import PropTypes from 'prop-types';
import React from 'react';
import CardRow from '../../../../components/CardRow/CardRow';
import CardRowCard from '../../../../components/CardRow/CardRowCard';
import { commodities } from '../../../../constants/commodities';
import PublicPriceDevelopment from './PublicPriceDevelopment';

const COMMODITIES = [commodities.ELE, commodities.GAS_CZVTP, commodities.GAS_THE];
const ProductPrices = () => COMMODITIES.map((commodity, index) =>
  <CardRow key={index}>
    <CardRowCard key={index}>
      <PublicPriceDevelopment key={index} type={commodity.key} years={commodity.years} />
    </CardRowCard>
  </CardRow>);

ProductPrices.propTypes = {
  customerId: PropTypes.number,
};

export default ProductPrices;
