import * as React from 'react';
import LanguageMenuContent from '../../../MenuContent/LanguageMenuContent';
import PopoverMenu from '../../../PopoverMenu/PopoverMenu';
import useAppContext from '../../../../hooks/context/useAppContext';
import FlagIconFactory from 'react-flag-icon-css';
import { isLanguageSupported, supportedLanguages } from '../../../../localizations/index';
import { makeStyles } from '@material-ui/core/styles';
import { useChangeUserLanguage } from '../../../../pages/UserDetailPage/hooks/api/userApi';
import { useCallback } from 'react';

const useStyle = makeStyles(() => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    gap: '7px',
    fontSize: '15px',
  },
}));

const LanguageMenu = () => {
  const FlagIcon = new FlagIconFactory(React, { useCssModules: false });
  const { user, setUser } = useAppContext();
  const language = supportedLanguages.find((supportedLanguage) => supportedLanguage.languageCode === user.language);
  const classes = useStyle();
  const { changeUserLanguage } = useChangeUserLanguage();

  const changeLanguage = useCallback(
    (supportedLanguage) => {
      if (isLanguageSupported(supportedLanguage.languageCode)) {
        changeUserLanguage(user.id, supportedLanguage.code).then(() => {
          const updatedUser = { ...user, language: supportedLanguage.languageCode };
          setUser(updatedUser);
        });
      }
    },
    [changeUserLanguage, user, setUser],
  );

  return (
    <PopoverMenu
      icon={
        <div className={classes.title}>
          <FlagIcon code={language?.flagCode} /> <div>{language.title}</div>
        </div>
      }>
      <LanguageMenuContent changeLanguage={changeLanguage} />
    </PopoverMenu>
  );
};

LanguageMenu.propTypes = {};

export default LanguageMenu;
