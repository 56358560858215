import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/logo/logoPN.svg';

const Title = styled.div`
  padding: 0 5px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const AppTitle = () => {
  return (
    <Title>
      <StyledLink to='/'>
        <Logo />
      </StyledLink>
    </Title>
  );
};

export default AppTitle;
