import React from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import AppsMenuContent from '../../../MenuContent/AppsMenuContent';
import PopoverMenu from '../../../PopoverMenu/PopoverMenu';

const AppsMenu = () => {
  return (
    <PopoverMenu icon={<AppsIcon />}>
      <AppsMenuContent />
    </PopoverMenu>
  );
};

AppsMenu.propTypes = {};

export default AppsMenu;
