import lazyLoad from '../utils/lazyLoad';
import userRoles from '../constants/userRoles';
import layouts from '../constants/layouts';

const CustomerDashboard = lazyLoad(import('../pages/CustomerDashboard'));
const NotFound = lazyLoad(import('../pages/NotFound/'));
const CustomersListPage = lazyLoad(import('../pages/CustomersListPage/'));
const ContractPage = lazyLoad(import('../pages/Contracts/Contract'));
const ContractDetailPage = lazyLoad(import('../pages/ContractDetailPage/'));
const TradingConfigPage = lazyLoad(import('../pages/TradingConfigPage/'));
const CustomerDetailPage = lazyLoad(import('../pages/CustomerDetailPage/'));
const TransactionsListPage = lazyLoad(import('../pages/TransactionsListPage/'));
const ContractsListPage = lazyLoad(import('../pages/ContractsListPage/'));
const TransactionDetailPage = lazyLoad(import('../pages/TransactionDetailPage/'));
const UsersListPage = lazyLoad(import('../pages/UsersListPage/'));
const UserDetailPage = lazyLoad(import('../pages/UserDetailPage/'));
const NotificationSettingsPage = lazyLoad(import('../pages/NotificationSettingsPage/'));
const TemplatesNotificationPage = lazyLoad(import('../pages/TemplatesNotificationPage/'));
const PriceAlertListPage = lazyLoad(import('../pages/PriceAlertListPage/'));
const ProfilePage = lazyLoad(import('../pages/ProfilePage/'));
const BillingPricesListPage = lazyLoad(import('../pages/BillingPricesListPage/'));
const MobileCardPage = lazyLoad(import('../pages/MobileCardPage/'));

const routes = [
  {
    path: '/',
    exact: true,
    component: CustomersListPage,
    roles: [userRoles.ADMIN, userRoles.KAM, userRoles.ASM, userRoles.CUSTOMER_MULTIPLE_COTRACTS, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/',
    exact: true,
    component: TransactionsListPage,
    roles: [userRoles.TRADER],
  },
  {
    path: '/billingprices',
    exact: true,
    component: BillingPricesListPage,
    roles: [userRoles.ADMIN],
  },
  {
    path: '/transactions',
    exact: true,
    component: TransactionsListPage,
    roles: [userRoles.ADMIN, userRoles.KAM, userRoles.ASM],
  },
  {
    path: '/contracts',
    exact: true,
    component: ContractsListPage,
  },
  {
    path: '/customers',
    exact: true,
    component: CustomersListPage,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM],
  },
  {
    path: '/users',
    exact: true,
    component: UsersListPage,
    roles: [userRoles.ADMIN, userRoles.TRADER],
  },
  {
    path: '/price-alert/:id',
    exact: true,
    layout: layouts.FORM,
    component: NotificationSettingsPage,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM, userRoles.CUSTOMER, userRoles.CUSTOMER_MULTIPLE_COTRACTS,
      userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/profile',
    exact: true,
    layout: layouts.FORM,
    component: ProfilePage,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM, userRoles.CUSTOMER, userRoles.CUSTOMER_MULTIPLE_COTRACTS,
      userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/settings/notifications',
    exact: true,
    layout: layouts.FORM,
    component: TemplatesNotificationPage,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM, userRoles.CUSTOMER, userRoles.CUSTOMER_MULTIPLE_COTRACTS,
      userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/customerDashboard/:customerId',
    exact: true,
    component: CustomerDashboard,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM, userRoles.CUSTOMER_MULTIPLE_COTRACTS,
      userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/contracts/:id/:idPanel',
    component: ContractDetailPage,
    exact: true,
    layout: layouts.FORM,
    roles: [userRoles.ADMIN],
  },
  {
    path: '/users/:id',
    component: UserDetailPage,
    exact: true,
    layout: layouts.FORM,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM],
  },
  {
    path: '/transaction/:id',
    component: TransactionDetailPage,
    exact: true,
    layout: layouts.FORM,
    roles: [userRoles.ADMIN],
  },
  {
    path: '/customers/:id',
    component: CustomerDetailPage,
    exact: true,
    layout: layouts.FORM,
    roles: [userRoles.ADMIN],
  },
  {
    path: '/trading-config',
    component: TradingConfigPage,
    exact: true,
    layout: layouts.FORM,
    roles: [userRoles.ADMIN, userRoles.TRADER, userRoles.KAM, userRoles.ASM],
  },
  {
    path: '/',
    exact: true,
    component: CustomerDashboard,
    roles: [userRoles.CUSTOMER, userRoles.CUSTOMER_VIEWER],
  },
  {
    path: '/pricealert',
    exact: true,
    component: PriceAlertListPage,
    roles: [userRoles.CUSTOMER, userRoles.CUSTOMER_MULTIPLE_COTRACTS, userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '/contract/:contractId',
    component: ContractPage,
    layout: layouts.CONTRACT,
  },
  {
    path: '/mobileCard',
    exact: true,
    component: MobileCardPage,
    roles: [userRoles.CUSTOMER, userRoles.TRADER, userRoles.CUSTOMER_MULTIPLE_COTRACTS, userRoles.CUSTOMER_VIEWER, userRoles.CUSTOMER_VIEWER_MULTIPLE_COTRACTS],
  },
  {
    path: '*',
    component: NotFound,
  },
];

const prepareRoutesObject = (routes, roles) => {
  const routesObject = roles.reduce((accumulator, role) => {
    accumulator[role] = routes.filter((route) => {
      return !route?.roles || route.roles.indexOf(role) !== -1;
    });
    return accumulator;
  }, {});
  checkRoutesObjectConsistency(routesObject);
  return routesObject;
};

const checkRoutesObjectConsistency = (routesObject) => {
  Object.keys(routesObject).forEach((key) => {
    const routes = routesObject[key];
    routes.forEach((route) => {
      if (routes.filter((item) => item.path === route.path).length > 1) {
        throw new Error(`Route duplicity - role: ${key}, path: ${route.path}`);
      }
    });
  });
};

export default prepareRoutesObject(routes, Object.values(userRoles));
